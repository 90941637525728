import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Notification",
  "type": "Other",
  "icon": "bellLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Notification`}</strong>{` component used to render a notification displayed either as an
absolute positioned box falling from the top viewport or as a static box.`}</p>
    <p>{`Use `}<strong parentName="p">{`Notification`}</strong>{` for server-side errors or messages. The `}<strong parentName="p">{`Notification`}</strong>{` can be
dismissible or not, depending on the use-case.`}</p>
    <hr></hr>
    <h1>{`Example`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type="info" dismissible>
  Example of a Notification component
</Notification>
`}</code></pre>
    <h1>{`Types`}</h1>
    <p>{`There are 4 different notification types : `}<inlineCode parentName="p">{`info`}</inlineCode>{`, `}<inlineCode parentName="p">{`warning`}</inlineCode>{`, `}<inlineCode parentName="p">{`success`}</inlineCode>{`, `}<inlineCode parentName="p">{`error`}</inlineCode>{`.`}</p>
    <p>{`They are all controlled via `}<inlineCode parentName="p">{`type`}</inlineCode>{` prop.`}</p>
    <h2>{`Info`}</h2>
    <p>{`Use info type to display a generic info message to the user`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type="info" dismissible>
  A new version of this application is available
</Notification>
`}</code></pre>
    <h2>{`Warning`}</h2>
    <p>{`Use warning notification for notifying the user of potential problems that may affect
his / her work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type="warning" dismissible>
  Internet connection is slow. Syncing is disabled
</Notification>
`}</code></pre>
    <h2>{`Success`}</h2>
    <p>{`Use success notification for notifying the user of successfully completed action.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type="success" dismissible>
  Invoice created successfully
</Notification>
`}</code></pre>
    <h2>{`Error`}</h2>
    <p>{`Use error notification for notifying the user of critical error that breaks the user
flow.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Notification type="error" dismissible>
  Invalid username or password
</Notification>
`}</code></pre>
    <h1>{`Absolute vs static notification boxes`}</h1>
    <p>{`A notification box can be rendered with entering / leaving animations from the top of
the window view port or can be rendered in a simple static block component.`}</p>
    <h2>{`Absolute positioned notification`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true",
        "live": "true",
        "editor": "true"
      }}>{`<div style={{ position: "relative", overflow: "hidden", height: "200px" }}>
  <Notification type="success" dismissible absolute>
    Absolute positioned notification
  </Notification>
</div>
`}</code></pre>
    <h1>{`Props`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"info"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"warning"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"success"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"error"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the notification box to be rendered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dismissible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If true it will render a close button on the right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onDismiss`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback for the close button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`absolute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the box should be absolute and not static`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      